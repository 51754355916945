'use strict';

document.addEventListener("DOMContentLoaded", function(event) {
    const OurProjectMenu = {
        menu: document.querySelector('.our-projects'),
        buttons: document.querySelectorAll('.ourProjectsBtn'),
        projects: document.querySelectorAll('.our-projects__project'),
        closeButton: document.querySelector('.closeBtnProject'),
        logo: document.querySelector('.header__logo'),
        menuTitle: document.querySelector('.our-projects__title'),
        state: false,

        openMenu: function() {
            const self = this;

            this.menu.addEventListener('mouseenter', function() {
                self.menu.classList.add('our-projects--open');
                self.logo.classList.add('header__logo--open');
            });

            this.menu.addEventListener('mouseleave', function() {
                self.menu.classList.remove('our-projects--open');
                self.logo.classList.remove('header__logo--open');
            });
        },
        setData: function() {
            function get(url) {
                return new Promise(function(resolve, reject) {
                    let req = new XMLHttpRequest();
                    req.open('GET', url);
                    req.onload = function() {
                        if (req.status == 200) {
                            resolve(req.response);
                        } else {
                            reject(Error(req.statusText));
                        }
                    }

                    req.onerror = function() {
                        reject(Error('Network Error'));
                    }

                    req.send();
                });
            }

            function getJSON(url) {
                return get(url).then(JSON.parse);
            }

            getJSON('https://api.eurohouse.ca/project/list').then(function(data) {
                let project = {};

                for (let i = 0; i < data.data.length; i++) {
                    if (data.data[i].attributes.is_published) {

                        project[i] = {
                            id: data.data[i].id,
                            title: data.data[i].attributes.title,
                            imgID: data.data[i].relationships[0].id,
                            priority: data.data[i].attributes.priority
                        };
                    }
                }

                return { project };
            }).then(function(data) {
                let sortProjects = [];

                function getImages() {
                    for (let prop in data.project) {
                        sortProjects.push([data.project[prop].priority, data.project[prop]]);
                    }

                    sortProjects.sort(function(a, b) {
                        return a[0] - b[0];
                    });

                    return new Promise((resolve, reject) => {
                        let imgURI = [];
                        let projectsCount = Object.keys(data.project).length;


                        for (let prop in data.project) {
                            getJSON(`https://api.eurohouse.ca/image/${data.project[prop].imgID}`).then(function(img) {
                                data.project[prop].imgURI = img.data.attributes.uri;
                                imgURI.push(img.data.attributes.uri);
                            });
                        }

                        let timerID = setInterval(function() {
                            if (imgURI.length == projectsCount) {
                                resolve();
                                clearInterval(timerID);
                            }
                        }, 100);
                    });
                }

                getImages().then(function() {
                    for (let i = 0; i < sortProjects.length; i++) {
                        let list = document.querySelector('.our-projects__menu');
                        let project = document.createElement('a');

                        project.classList.add('our-projects__project');

                        project.href = `/project/${sortProjects[i][1].id}`;
                        project.style.cssText = `background-image: url(https://api.eurohouse.ca/static${sortProjects[i][1].imgURI}); transition-delay: ${(i * 100) + 300}ms;`;

                        list.appendChild(project);
                    }
                });
            });
        },
        init: function() {
            this.openMenu();
            this.setData();
        }
    }
    
    OurProjectMenu.init();
});